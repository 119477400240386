// extracted by mini-css-extract-plugin
export var box = "content-box-module--box--tQOAX";
export var columns = "content-box-module--columns--PdLPX";
export var masonry = "content-box-module--masonry--E9P26";
export var modeCenter = "content-box-module--mode-center--GCQc5";
export var modeFull = "content-box-module--mode-full--llhas";
export var modeLeft = "content-box-module--mode-left--oiDiE";
export var modeRight = "content-box-module--mode-right--08HET";
export var sideImage = "content-box-module--side-image--lTAFs";
export var wordBreaks = "content-box-module--word-breaks--R8hz-";
export var wrapper = "content-box-module--wrapper--McpQN";